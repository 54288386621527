@use 'sass:map';
@use "@angular/material" as mat;
@include mat.core();

$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$wingsapp-theme: mat.define-light-theme((color: (primary: $my-app-primary, accent: $my-app-accent, warn: $my-app-warn, ), ));

@include mat.all-component-themes($wingsapp-theme);

@import "~bootstrap/dist/css/bootstrap.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: rgb(213, 213, 213);
}

.wingsapp-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: start;
}

header {
  height: 70px;
  display: flex;
  flex-direction: row-reverse;
  background: mat.get-color-from-palette($my-app-primary, 500);
}

.wingsapp-header-left {
  flex: 1 1 auto;
  color: #ffffff;
  background: url("/assets/img/wingsapp_30h_white.png") 35px 20px no-repeat;
}

.wingsapp-header-left p {
  font-size: 1em;
  color: #ffffff;
  padding: 25px;
  margin-left: 195px;
}

.wingsapp-header-right {
  flex: 0 0 150px;
  overflow: auto;
}

.wingsapp-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background: #ffffff;
}

.wingsapp-menu {
  flex: 0 0 200px;
  background: #ffffff;
}

.wingsapp-main-content {
  flex: 1 1 auto;
  overflow: auto;
}

.wingsapp-main-content .row{
  margin-right: 0;
}

.wingsapp-menu-button {
  width: 100%;
}

.wingsapp-menu-button .mat-button-wrapper mat-icon {
  float: left;
  margin-top: 5px;
}

.wingsapp-menu-button .mat-button-wrapper span {
  margin-left: 5px;
}

.cdk-overlay-pane .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background: none
}

.cdk-overlay-pane .errorSnackBar .mat-mdc-snack-bar-label {
  background: #ffe4df;
  color: #ea1b24;
  text-align: center;
}

.cdk-overlay-pane .successSnackBar .mat-mdc-snack-bar-label {
  background: #f0ffdc;
  color: #008000;
  text-align: center;
}

 .mdc-snackbar {
  margin-top: 50px;
}

.loading-shade {
  display: inline-block;
  vertical-align: middle;
}

.page-title {
  vertical-align: middle;
  margin-right: 5px;
}

.wingsapp-card-content mat-form-field .mdc-text-field {
  background-color: #ffffff;
}

//Material Design
.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Noto Sans';
  }
}

.mdc-card .mat-mdc-card-header
{
  justify-content: space-between;
}


.mdc-card .mdc-card__actions {
  align-items: stretch;
}

.mat-mdc-card-header-text .mat-mdc-card-title {
  display: block;
  margin: 0;
  margin-bottom: 15px;
}

.mat-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.mat-row,
.mat-header-row,
.mat-footer-row {

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 10px 0px 10px 20px;
  }
}

td.mat-cell {
  .mat-raised-button {
    padding: 0 8px;
  }
}

.mat-card-header .mat-card-title {
  margin-bottom: 12px;
}

.mat-card .mat-card-header-text {
  margin: 0 16px 0 0;
}

mat-tab-body .mat-tab-body-content,
.div-body-content {
  padding: 20px;
}

.checkbox label,
.radio label,
label {
  font-size: 1em;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0 0 0 .75em;
  background: none;
}